import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="search"
export default class extends Controller {
  static values = {
      category: String,
      district: String,
  }

  connect() {
  }

  changeCategory(event) {

  }

  search(event) {
    event.preventDefault();
    //
    // let city = event.target.parentElement.getElementsByClassName("filter-city")[0].value;
    // let district = this.districtValue;
    // let kind = this.categoryValue;
    // let fullPath = [city, district].filter((e) => e !== "").join("/");
    //
    // let result = "";
    //
    // if(kind === "" && city !== "" && district === "") {
    //   result = `/orte/${city}`;
    // } else if (kind === "" && city !== "" && district !== "") {
    //   result = `/orte/${city}/${district}`;
    // } else if (kind !== "" && city !== "" && district !== "") {
    //   result = `/orte/${city}/${district}/${kind}`;
    // } else if (city !== "") {
    //   result = `/orte/${city}/${kind}`;
    // } else if (kind !== "") {
    //   result = `/orte/${kind}`;
    // }

    // if (query !== "" && result === "")
      // result = `/orte?q=${query}`
    // else if (query !== "")
    let query = event.target.parentElement.getElementsByClassName("filter-query")[0].value;
    let currentUrl = new URL(document.location.href);
    currentUrl.searchParams.set('q', query);
    document.location.href = currentUrl.toString();
  }

  changeCity(event) {
    console.log("changeCity")
    get(`/districts?city=${event.target.value}`, {
      responseKind: "turbo-stream"
    })
  }

  changeDistrict(event) {

  }

}
